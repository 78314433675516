var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "langs" },
    [
      _c("Header", [
        _c("i", {
          staticClass: "me-icon-back",
          attrs: { slot: "left-one" },
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          },
          slot: "left-one"
        })
      ]),
      _c(
        "van-cell-group",
        { attrs: { title: "" } },
        _vm._l(Object.keys(_vm.langs), function(lang, index) {
          return _c(
            "van-cell",
            {
              key: index,
              attrs: { title: _vm.langs[lang] },
              on: {
                click: function($event) {
                  return _vm.setLang(lang)
                }
              }
            },
            [
              lang.indexOf(_vm.target) !== -1
                ? _c("van-icon", {
                    staticClass: "icon",
                    attrs: {
                      slot: "right-icon",
                      color: "#33cc66",
                      name: "checked",
                      size: "22px"
                    },
                    slot: "right-icon"
                  })
                : _vm._e()
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }