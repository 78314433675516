<template>
  <div class="langs">
    <Header>
      <i slot="left-one" @click="$router.go(-1)" class="me-icon-back"></i>
    </Header>
    <van-cell-group title>
      <van-cell
        v-for="(lang, index) in Object.keys(langs)"
        :key="index"
        :title="langs[lang]"
        @click="setLang(lang)"
      >
        <van-icon
          class="icon"
          v-if="lang.indexOf(target) !== -1"
          color="#33cc66"
          name="checked"
          size="22px"
          slot="right-icon"
        />
      </van-cell>
    </van-cell-group>
  </div>
</template>
<script>
import Header from "@/components/business/h5/header";
import { updateGroupTrans } from "@/api/im";
export default {
  name: "lang",
  data() {
    return {
      curLang: "",
      langs: [],
      target: "",
      type: "",
      curUser: {},
      chatObj: {},
      language: ""
    };
  },
  components: {
    Header
  },
  async created() {
    this.type = this.$route.params.type;
    this.target = this.$route.params.target || "zh";
    this.curLang = this.$i18n.locale;
    this.langs = this.$i18n.messages[this.curLang].langs;
    this.curUser = JSON.parse(localStorage.getItem("userinfo"));
    this.chatObj = JSON.parse(localStorage.getItem("chatObj"));
    await this.$nextTick()
    this.language = localStorage.getItem("langCode") || "1";
    Object.keys( this.$i18n.messages).map((key)=>{
     const item = this.$i18n.messages[key]
     this.langs[key] += `(${item.langName})`
    })
  },
  methods: {
    setLang(lang) {
      this.target = lang;
      this.handlerUpdateGroupTrans(lang.slice(0, 2));
    },
    async handlerUpdateGroupTrans(lang) {
      let params = {
        providerId: this.curUser.id,
        language: this.language,
        token: this.curUser.token,
        translateTarget: lang
      };

      if (this.type === "group") {
        params.groupId = this.chatObj.id;
      }
      if (this.type === "chat" || this.type ==='user') {
        params.pairU1 = this.curUser.id;
        params.pairU2 = this.chatObj.id;
      }

      this.$toast.loading();
      const result = await updateGroupTrans(params).catch(e => {
        console.log(e);
      });
      if (result && result.code === 200) {
        this.$toast.success();
        this.$router.go(-1);
      } else this.$toast.fail();
    }
  }
};
</script>
<style lang="less">
.van-toast {
  max-height: 90px;
  .van-loading {
    height: 100%;
    padding: 0;
  }
}
.langs {
  padding-top: 54px;
}
</style>